/*
 * @description: 公用的 data 和 methods v0.01
 * @author: https://www.51xuediannao.com/
 * @update: 2020-02-28 18:02
 */
import { util } from "./util.js";
import { _OPTIONS } from "./options.js";
import { _CONFIG } from "@/config/config";
import { _rules } from "./rules.js";
import TeamServices from "../services/team.js";
import ActivityServices from "../services/activity.js";
import UserServices from "../services/user.js";

let _data = {
  _CONFIG: _CONFIG,
  showPop: false,
  showNoData: false,
  listDataConfig: {
    params: {
      pageSize: 20,
      page: 1,
    },
  },
  paginationLayout: "prev, pager, next,total",
  countPrice: 0,
  listServices: null,
  listData: [],
  groupsData: null,
  pageCount: 1,
  dataListCount: 0,
  loadingPages: true,
  loadMoreLoading: true,
  dataInit: true,
  pageEnd: false,
  isLoad: true,
  Loading: false,
  uploadData: { uploadTo: "local", uploadDir: "images" },
  uploadAction: `${_CONFIG.host}/boss/upFile`,
  uploadHeaders: {
    Authorization: util.getLocalStorage("TOKEN") || "head",
  },
  acceptImage: ".jpg,.jpeg,.png",
  acceptVideo: ".mp4",
  acceptDoc: ".pdf,.doc,.docx,.xls,.xlsx",
  imageSize: 1, //M
  videoSize: 50, //M
  options_activity: [],
  options_activity_copy: [],
  options_team: [],
  team_name: "",
  options_business: [],
  dateTimeRange: "",
  shortcuts: [],
  dot: "---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------",
};

let _methods = {
  init() {
    this._OPTIONS = _OPTIONS;
    this.userInfo = util.getLocalStorage("UserInfo") ? {} : util.getLocalStorage("UserInfo");
    this.curRoute = this.getCurRoute();
  },
  getApp(key) {
    let obj = this.GlobalData;
    if (key && typeof obj[key] === "undefined") {
      return null;
    }
    return key ? obj[key] : obj;
  },
  setApp(obj = {}) {
    this.GlobalData = util._extends(true, this.GlobalData, obj);
    return this;
  },

  setLoginToken(data, response) {
    util.setLocalStorage("TOKEN", response.headers.authorization);
    util.setLocalStorage("userInfo", data);
    util.setLocalStorage("systemConfig", data.system);

    if (data.config) {
      util.setLocalStorage("userConfig", data.config);
    }
  },

  getRules(requiredFields) {
    let requiredFieldsArray = requiredFields.split(",");
    let requiredRules = [];
    for (let i = 0; i < requiredFieldsArray.length; i++) {
      let _obj = _rules.find((m) => {
        return m.name === requiredFieldsArray[i];
      });
      typeof _obj !== "undefined" && requiredRules.push(_obj);
    }
    return requiredRules;
  },
  getRequiredFields() {
    let requiredEls = document.querySelectorAll("[required]");
    let names = [];
    for (let item of requiredEls) {
      !item.getAttribute("data-not") && names.push(item.getAttribute("name"));
    }
    return names.join(",");
  },
  getRulesDiff(names, rules) {
    let _rules = rules || this.rules || [];
    let _names = typeof names === "string" ? names.split(",") : names;
    let arrayError = [];
    console.log("测试规则是否存在：\n");
    for (let item of _names) {
      let _rule = _rules.find((m) => {
        return item === m.name;
      });
      if (_rule) {
        let _rule_string = JSON.stringify(_rule);
        console.info(`name：${item}->rule：${_rule_string}`);
      } else {
        let item_string = item ? item : "null";
        console.error(`name：${item_string}->rule：undefined`);
        arrayError.push(item);
      }
    }
    console.log(arrayError.join(","));
  },
  submitFormBefore() {
    //最后一次拦截
    return true;
  },
  submitFormAfter() {},
  goBack(path = "") {
    if (path) {
      this.$router.push({ path: path });
      return;
    }
    this.$router.go(-1);
  },

  getCurRoute() {
    return this.$route;
  },

  cdnFilePath(path, defaultPath = "", resize_w = 0) {
    let systemConfig = util.getLocalStorage("systemConfig", {});
    console.log("systemConfig->", systemConfig);
    let cdn = systemConfig.cdn || "";
    if (cdn.indexOf("{{cdn}}") > -1) {
      let url = cdn.replace("{{cdn}}", cdn);
      return resize_w > 0 ? `${url}?x-oss-process=image/resize,w_${resize_w}` : url;
    }
    let _path = path ? path : defaultPath;
    if (!_path) {
      return "";
    }
    if (_path.indexOf("//") > -1) {
      return _path;
    }

    if (
      _path.substring(0, 8) === "/static/" ||
      _path.substring(0, 8) === "/upload/" ||
      _path.substring(0, 9) === "/uploads/"
    ) {
      return _CONFIG.host + _path;
    }

    return resize_w > 0 ? `${cdn}/${_path}?x-oss-process=image/resize,w_${resize_w}` : `${cdn}/${_path}`;
  },

  pageBottomEvents(obj) {
    console.log("pageBottomButtons", obj);
    let value = obj.value;
    let eventName = "pageBottomEvent" + value.charAt(0).toUpperCase() + value.slice(1);
    if (typeof eventName === "undefined") {
      console.error(`找不到 ${eventName} 这个方法`);
      return;
    }
    this[eventName](obj);
  },
  formatDate(time) {
    if (time == null || time == "") return "";
    let date = new Date(time);
    let year = date.getFullYear();
    let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
  },

  formatYmd(date = "") {
    if (!date) {
      return "";
    }
    return util.formatDate("YYYY-MM-DD hh:mm", date);
  },

  formatOption(value, optionName) {
    if (typeof _OPTIONS[optionName] === "undefined") {
      return "";
    }
    let obj = _OPTIONS[optionName].find((item) => {
      return item.value == value;
    });
    return obj ? obj.label || obj.name : "";
  },
  formattingYMD(value) {
    if (value === "" || value.indexOf("0001-01-01") > -1 || value.indexOf("0000-00-00") > -1) {
      return "";
    }
    const date = new Date(value);
    let Y = date.getFullYear();
    let M = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    //return Y + "-" + M + "-" + D;改value-format没用，我也不知道为什么
    //最后传给后台的格式YYYYMMDD
    return Y + "-" + M + "-" + D;
  },
  formattingTime(value) {
    if (value === undefined) {
      return "";
    }
    if (value === "" || value.indexOf("0001-01-01") > -1 || value.indexOf("0000-00-00") > -1) {
      return "";
    }
    let date = new Date(value);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    let hours = date.getHours();
    if (hours < 10) {
      hours = "0" + hours;
    }
    let minutes = date.getMinutes();
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    let seconds = date.getSeconds();
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    let FormattedDateTime = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    return FormattedDateTime;
  },

  /*
      * 获取数组对象的 names 数组  getArrayObjNames 获取其中的 name 数组，用于 xAxis_data 和 legend_data
      * [
          {
              value: 60,
              name: '博士'
          },
          {
              value: 80,
              name: '硕士'
          },
          {
              value: 200,
              name: '本科'
          },
      ]
      * */
  getArrayObjNames(arrayObj) {
    let keys = [];
    for (let i = 0; i < arrayObj.length; i++) {
      let name = arrayObj[i]["name"] || "其他";
      keys.push(name);
    }
    return keys;
  },

  /*
      * 分组的柱状或者折线数据格式转换 getGroupData
      * json  服务端返回的 json，约定格式查看下方 JSON: 段的解释
      * seriesTpl={} / []  数据展示的模板，比如 柱状的每个柱子，折线的每个折线都是可以有不同的外观，所以要各自配置模板，
       服务端返回数据的数据格式如：
          {
              "Flag":1,
              "Msg":"OK",
              "Code":200,
              "Data":[{...},{...}...] // json 数据格式约定如下
          }
       JSON: 服务端约定 json 的数据格式：
       classify 必选，x 轴的分类方式
       data 必选，
       name 必选，
       value 必选，
       jumpUrl 自定义的参数，可以自定义多个，用于图形和服务端事件交互
       示例：
       [
          {
              classify: '煤炭开采',
              data: [
                  {
                      name: '2018',
                      value: 320,
                      jumpUrl:'https://www.xxx.com/s?q=2018&classify=1'
                  },
                  {
                      name: '2019',
                      value: 120,
                      jumpUrl:'https://www.xxx.com/s?q=2019&classify=1'
                  },
                  {
                      name: '2020',
                      value: 220,
                      jumpUrl:'https://www.baidu.com/s?q=2020&classify=1'
                  }
              ]
          },
          {
              classify: '煤矿支护',
              data: [
                  {
                      name: '2018',
                      value: 300,
                      jumpUrl:'https://www.xxx.com/s?q=2018&classify=2'
                  },
                  {
                      name: '2019',
                      value: 220,
                      jumpUrl:'https://www.xxx.com/s?q=2019&classify=2'
                  },
                  {
                      name: '2020',
                      value: 280,
                      jumpUrl:'https://www.baidu.com/s?q=2020&classify=2'
                  }
              ]
          },
      * ]
      * 转换成 echart 需要如下格式，
      * [
          {
              name: '2018',
              type: 'bar',
              emphasis: {
                  focus: 'series'
              },
              data: [{jumpUrl: "xxx",value: 300},{jumpUrl: "xxx",value: 320}],
          },
          {
              name: '2019',
              type: 'bar',
              emphasis: {
                  focus: 'series'
              },
              data: [{jumpUrl: "xxx",value: 380},{jumpUrl: "xxx",value: 340}],
          },
          {
              name: '2020',
              type: 'bar',
              emphasis: {
                  focus: 'series'
              },
              data: [{jumpUrl: "xxx",value: 420},{jumpUrl: "xxx",value: 360}],
          }
      ];
      * */
  getGroupData(json, seriesTpl = {}) {
    let seriesTplDataType = util.isArray(seriesTpl); //seriesTpl 可以使对象或者数组
    let xAxis_data = [];
    for (let item of json) {
      xAxis_data.push(item.classify);
    }

    let legend_data = [];
    let _data_temp = [];
    for (let i = 0; i < json[0].data.length; i++) {
      let _name = json[0].data[i].name;
      legend_data.push(_name);
      let _seriesTpl = seriesTplDataType ? seriesTpl[i] : seriesTpl;
      let _series_item = util.extend(true, {}, _seriesTpl, {
        name: _name,
        data: [],
      });

      _data_temp.push(_series_item);
    }

    let _data_temp2 = [];
    for (let j = 0; j < _data_temp.length; j++) {
      let _temp = [];
      for (let k = 0; k < json.length; k++) {
        _temp.push(json[k].data[j]);
      }
      _data_temp2.push(_temp);

      _data_temp[j].data = _temp;
    }
    console.log("_data_temp", _data_temp);
    return {
      legend_data: legend_data,
      mAxis_data: xAxis_data, //主轴 通常是 xAxis.data , 也有是有 y 轴的情况
      series: _data_temp,
    };
  },
  pieOption(config = {}) {
    return util.extend(
      true,
      {},
      {
        title: {
          text: "某站点用户访问来源",
          subtext: "纯属虚构",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          show: false,
          orient: "vertical",
          left: "left",
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
        },
        series: [
          {
            label: {
              color: "#83f352",
              fontSize: 14,
              fontWeight: 400,
              textBorderWidth: 0,
              textBorderColor: "#83f352",
              formatter: "{b}\n{c} ({d}%)",
              lineHeight: 22,
            },
            name: "访问来源",
            type: "pie",
            radius: "60%",
            center: ["50%", "50%"],
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
      config
    );
  },
  barOption(config = {}) {
    return util.extend(
      true,
      {},
      {
        title: {
          textAlign: "left",
          left: 18,
          top: 12,
          subtext: ``,
          subtextStyle: {
            textAlign: "right",
            left: "center",
          },
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          top: "20%",
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} <br/> {c}",
        },
        legend: {
          textStyle: {
            color: "#83f352",
            fontSize: 12,
          },
        },
        xAxis: {
          type: "category",
          axisLine: {
            lineStyle: {
              color: "#83f352",
            },
          },
          axisLabel: {
            inside: false,
            textStyle: {
              fontSize: "10",
            },
          },
          data: [], // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#83f352",
            },
          },
        },
        series: [
          {
            data: [], // [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200],
            type: "bar",
            itemStyle: {
              emphasis: {
                show: true,
                color: "#6086fb",
              },
            },
          },
        ],
      },
      config
    );
  },
  gaugeOption(config = {}) {
    return util.extend(
      true,
      {},
      {
        series: [
          {
            title: {
              offsetCenter: [0, "82%"],
              fontSize: 18,
            },
            type: "gauge",
            min: 0,
            max: 100,
            progress: {
              show: true,
              width: 10,
            },
            axisLine: {
              lineStyle: {
                width: 10,
              },
            },
            axisTick: {
              show: true,
              distance: 0,
              length: 6,
              lineStyle: {
                color: "#666",
                width: 1,
              },
            },
            splitLine: {
              distance: 0,
              length: 12,
              lineStyle: {
                width: 1,
                color: "#666",
              },
            },
            axisLabel: {
              distance: 15,
              color: "#333",
              fontSize: 14,
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 20,
              itemStyle: {
                borderWidth: 10,
              },
            },
            detail: {
              valueAnimation: true,
              fontSize: 18,
              offsetCenter: [0, "36%"],
            },
            data: [
              {
                name: "成绩评定",
                value: 70,
              },
            ],
          },
        ],
      },
      config
    );
  },
  pieNest(config = {}) {
    return util.extend(
      true,
      {},
      {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          show: false,
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          data: [], //['专科', '本科', '研究生', '其他']
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            selectedMode: "single",
            radius: [0, "40%"],
            label: {
              position: "inner",
              fontSize: 14,
            },
            labelLine: {
              show: false,
            },
            data: [
              /*{value: 1548, name: '学士'},
                                      {value: 775, name: '硕士'},
                                      {value: 679, name: '博士', selected: true}*/
            ],
          },
          {
            name: "访问来源",
            type: "pie",
            radius: ["52%", "68%"],
            labelLine: {
              length: 12,
            },
            label: {
              color: "#83f352",
              fontSize: 14,
              fontWeight: 400,
              textBorderWidth: 0,
              textBorderColor: "#83f352",
              formatter: "{b}\n {c}\n{d}%",
              lineHeight: 20,
            },
            data: [
              /*{value: 1048, name: '专科'},
                                      {value: 335, name: '本科'},
                                      {value: 310, name: '研究生'},
                                      {value: 251, name: '其他'},*/
            ],
          },
        ],
      },
      config
    );
  },

  popClose() {
    this.popShow = false;
  },

  pageSearchEvent() {
    typeof this.pageSearchEventEmit !== "undefined" && this.pageSearchEventEmit();
  },

  //处理分类 级别
  getSonsTree(arrayObj, iniPid = "0") {
    console.log("arrayObj", arrayObj);
    let _array = arrayObj;
    let arrayTemp = [];
    let fn = function (id, lev) {
      for (let i = 0; i < _array.length; i++) {
        if (id === _array[i]["pid"]) {
          _array[i]["lev"] = lev;
          arrayTemp.push(_array[i]);
          fn(_array[i]["Id"], lev + 1);
        }
      }
    };
    fn(iniPid, 0);
    console.log("arrayTemp", arrayTemp);
    return arrayTemp;
  },
  unfoldData(arrayObj) {
    let array = [];
    let fn = function (arr) {
      for (let i = 0; i < arr.length; i++) {
        let item = arr[i];
        let chirld = item.chirld;
        delete item.chirld;
        array.push(item);
        if (chirld.length) {
          fn(chirld);
        }
      }
      //array = array.concat(tempArray)
    };
    fn(arrayObj);

    /*let array_new = []
            for (let item of array){
                array_new.push(item)
                for(let j=0;j<array.length;j++){
                    let index = array_new.findIndex(value=>{
                        return value.Id === item.Id
                    })
                    if(!index && array[j].pid === item.Id){
                        array_new.push(array[j])
                    }
                }
            }*/

    return array;
  },

  onUploadPreview(file) {
    console.log("onUploadPreview", file);
  },
  onUploadRemove(file, fileList) {
    console.log("onUploadRemove", file, fileList);
  },
  //自行实现 根据实际情况重写此方法
  onUploadSuccess(response, file, fileList) {
    console.log("onUploadSuccess", response, file, fileList);
    this.model.thumbnail = response.result;
  },

  msg(msg, type, config) {
    let typeMap = {
      true: "success",
      false: "error",
      1: "success",
      0: "error",
      "-1": "warning",
    };

    let _config = util.extend(
      {},
      {
        message: msg,
        type: typeMap[type],
      },
      config
    );

    this.$message(_config);
  },

  // tokenConfig->response.headers.config
  setYearQuarter(year, quarter, tokenConfig) {
    //配置前端使用
    util.setLocalStorage("defaultYear", year);
    util.setLocalStorage("defaultQuarter", quarter);
    //把配置传回给服务端
    util.setLocalStorage("TOKENCONFIG", tokenConfig);
  },
  getYearQuarter(type = "") {
    let year = util.getLocalStorage("defaultYear");
    let quarter = util.getLocalStorage("defaultQuarter");
    console.log("getYearQuarter", year, quarter);
    if (!type) {
      return {
        year: year,
        quarter: quarter,
        yearQuarter: `${year}-${quarter}`,
      };
    }

    if (type === "y") {
      return year;
    }
    if (type === "q") {
      return quarter;
    }
    if (type === "y-q") {
      return `${year}-${quarter}`;
    }
  },

  formSubmitValidate(formName, onSuccess = null) {
    return this.$refs[formName]
      .validate()
      .then((ok) => {
        if (!ok) {
          return false;
        }
        onSuccess && onSuccess();
        return ok;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  jumpByPath(path = "") {
    if (!path) {
      return;
    }
    this.$router.push({ path: path });
  },

  currentChange(page) {
    this.listDataConfig.params.page = page;
    this.queryData();
  },

  resetListDataConfig() {
    let urlQuery = this.$route.query || {};
    this.listDataConfig = {
      params: util.extend(
        {
          pageSize: 20,
          page: 1,
        },
        urlQuery
      ),
    };
  },
  //获取所有活动
  getActivity(teamId) {
    ActivityServices.showAll({ team_id: teamId }).then((res) => {
      let opt = [];
      for (let item of res.Data) {
        opt.push({
          label: item.title,
          value: item.Id,
          team_id: item.team_id,
        });
      }
      this.options_activity = opt;
      this.options_activity_copy = opt;
    });
  },
  changeActivity(obj) {
    console.log("changeActivity", obj);
    this.listDataConfig.params.activity_id = obj.value;
    this.activity_title = obj.label;
    this.queryData();
  },

  getBusiness() {
    UserServices.showBusiness().then((res) => {
      let opt = [];
      for (let item of res.Data) {
        let is_delete = item.is_delete === 1 ? "（离职）" : "";
        opt.push({
          label: item.real_name + is_delete,
          value: item.Id,
        });
      }
      this.options_business = opt;
    });
  },
  changeBusiness(obj) {
    console.log("changeBusiness", obj);
    this.listDataConfig.params.referrer_top_id = obj.value;
    this.referrer_realname = obj.label;
    this.queryData();
  },

  /*
       <div class="col col-date-time-range" style="margin-left: 6px;">
          <el-date-picker
              v-model="dateTimeRange"
              type="datetimerange"
              :shortcuts="shortcuts"
              :unlink-panels = "true"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="YYYY-MM-DD HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
              @change="dateTimeRangeChange"
          />
        </div>
      * */
  dateTimeRangeChange(value) {
    console.log("dateTimeRangeChange", value);
    if (!value) {
      this.listDataConfig.params.dateTimeRange = "";
    } else {
      this.listDataConfig.params.dateTimeRange = this.dateTimeRange.join(",");
    }
    this.queryData();
  },
  makeShortcuts() {
    this.shortcuts = [
      {
        text: "最近1天(实)",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24);
          return [start, end];
        },
      },
      {
        text: "最近1周",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        },
      },
      {
        text: "最近1个月",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        },
      },
      {
        text: "最近3个月",
        value: () => {
          const start = new Date();
          const end = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        },
      },
      {
        text: "今天",
        value: () => {
          const start = util.formatDate("YYYY-MM-DD") + " 00:00:00";
          const end = util.formatDate("YYYY-MM-DD") + " 23:59:59";
          return [start, end];
        },
      },
      {
        text: "昨天",
        value: () => {
          const start = util.date.getDate(-1) + " 00:00:00";
          const end = util.date.getDate(-1) + " 23:59:59";
          return [start, end];
        },
      },
      {
        text: "最近2天",
        value: () => {
          const start = util.date.getDate(-1) + " 00:00:00";
          const end = util.date.getDate(0) + " 23:59:59";
          return [start, end];
        },
      },
      {
        text: "最近3天",
        value: () => {
          const start = util.date.getDate(-2) + " 00:00:00";
          const end = util.date.getDate(0) + " 23:59:59";
          return [start, end];
        },
      },
    ];
  },
  compareTime(time1, time2) {
    var date1 = new Date(time1);
    var date2 = new Date(time2);
    if (date1 < date2) {
      return -1;
    } else if (date1 > date2) {
      return 1;
    }
    return 0;
  },

  //视频上传
  onUploadChangeVideo(file, fileList) {
    let accept = this.uploadFileRef.accept.toLowerCase().split(",");
    console.log("this.uploadFileRef=>", this.uploadFileRef);
    let suffix = file.name.split(".").pop().toLowerCase();
    if (!accept.includes("." + suffix)) {
      this.msg(`文件类型必须是：${accept.join("，")}`, 0);
      this.uploadFileRef.handleRemove(fileList[0]);
      return;
    }

    let size = file.size / 1024 / 1024;
    if (size > this.videoSize) {
      this.msg(`文件大小不能超过${this.videoSize}M`, 0);
      this.uploadFileRef.handleRemove(fileList);
      return;
    }
    console.log("onUploadChangeFile", size, file, this.uploadFileRef);
  },
  onUploadSuccessThumb(response, file, fileList) {
    console.log("onUploadSuccess", response, file, fileList);
    this.model.thumb = response.Data.filePath;
    this.$refs.uploadImage.clearFiles();
  },

  //获取所有团队
  getTeam() {
    TeamServices.show().then((res) => {
      let opt = [];
      for (let item of res.Data) {
        opt.push({
          label: item.title,
          value: item.Id,
        });
      }
      this.options_team = opt;
    });
  },
  changeTeam(obj) {
    console.log("changeTeam", obj);
    this.listDataConfig.params.team_id = obj.value;
    this.team_name = obj.label;

    console.log("this.options_activity", this.options_activity);
    //过滤当前团队的 活动
    if (this.options_activity_copy.length) {
      let array = [];
      for (let m of this.options_activity_copy) {
        if (m.team_id === obj.value) {
          array.push(m);
        }
      }
      this.options_activity = array;
    }
    this.queryData();
  },

  changeIsDelete(obj) {
    this.listDataConfig.params.is_delete = obj.value;
    this.queryData();
  },

  getFilterData(name, field, option = "", checkedType = 2) {
    let data = "";
    if (typeof option === "string") {
      data = _OPTIONS[!option ? util.convertToCamelCase(field) : option];
    } else {
      data = option;
    }

    return {
      name: name,
      field: field,
      data: data,
      checkedType: checkedType, //1 多选 2 单选
    };
  },
  getFilterDatas(filterArray) {
    let temp = [];
    for (let i = 0; i < filterArray.length; i++) {
      let item = filterArray[i].split(",");
      let option = item[2] || "";
      let checkedType = item[3] || 2;
      temp.push(this.getFilterData(item[0], item[1], option, checkedType));
    }
    return temp;
  },
};

let _watch = {
  /*"model.unitCode": function (newVal, oldVal) {
          let obj = this._OPTIONS.unit.find(item => {
              return item.value === newVal;
          });
          if (!obj) {
              return
          }
          this.model.unitName = obj.name;
          console.log('this.model.unitName', this.model.unitName)
      }*/
};

export { util, _data, _methods, _watch, _OPTIONS, _CONFIG };
