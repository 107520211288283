import { $http } from "../common/axios.js";
import { ServicesGlobal } from "./services.js";

class ActivityServices extends ServicesGlobal {
  constructor(props) {
    super(props);
  }

  create(data = {}) {
    let params = this.postParams(data);
    return $http.post(`${this.path.api}/activity/create`, params).then(function (response) {
      return response.data;
    });
  }

  delete(id, data = {}) {
    let params = this.postParams(data);
    return $http.delete(`${this.path.api}/activity/delete/${id}`, params).then(function (response) {
      return response.data;
    });
  }

  update(id, data = {}) {
    let params = this.postParams(data);
    return $http.put(`${this.path.api}/activity/update/${id}`, params).then(function (response) {
      return response.data;
    });
  }

  updateField(id, data = {}) {
    let params = this.postParams(data);
    return $http.put(`${this.path.api}/activity/updateField/${id}`, params).then(function (response) {
      return response.data;
    });
  }

  show(data = {}) {
    return $http.get(`{boss}/activity/show`, { params: data }).then(function (response) {
      return response.data;
    });
  }

  showWww(data = {}) {
    return $http.get(`{www}/activity/show`, { params: data }).then(function (response) {
      return response.data;
    });
  }

  showAll(data = {}) {
    return $http.get(`{boss}/activity/showAll`, { params: data }).then(function (response) {
      return response.data;
    });
  }

  details(id, data = {}) {
    return $http.get(`${this.path.api}/activity/details/${id}`, { params: data }).then(function (response) {
      return response.data;
    });
  }

  settlementReferrer(data = {}) {
    return $http.get(`{boss}/activity/settlementReferrer`, { params: data }).then(function (response) {
      return response.data;
    });
  }
}

export default new ActivityServices();
