/**
 * @author: https://www.51xuediannao.com/
 * @update: 2021-09-23 12:02
 */
const _CONFIG = Object.assign(
  {
    debug: true,
    ENV: process.env.VUE_APP_ENV,
    host: process.env.VUE_APP_HOST,
    apiPath: process.env.VUE_APP_API,
    api: {
      boss: process.env.VUE_APP_API + "/boss",
      www: process.env.VUE_APP_API + "/www",
    },
    hostWs: process.env.VUE_APP_HOST_WS,
    hostKey: "ddc0bcccee4ccb143ae8a27e113fb89f",
    apiOSSPath: "https://lingbaikeji-car.oss-cn-beijing.aliyuncs.com/",
    siteUrl: process.env.VUE_APP_HOST,
    cdn: "https://lingbaikeji-car.oss-cn-beijing.aliyuncs.com/",
    rootPath: "",
    siteName: "车之友",
    systemFullName: "车之友",
    shortName: "车之友",
    column: [],
    scanCodeLogin: false,
    prefix: "LB-HK-BOSS",
  },
  {}
);

export { _CONFIG };
