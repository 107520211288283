<template>
  <div class="sidebar" id="sidebar">
    <div class="site-name" data-path="/" @click="_jump">{{ systemConfig.short_name }}</div>

   <template v-if="toolsRoutes1.length">
     <ul class="main-nav" >
       <li v-for="(item,index) in toolsRoutes1" :key="index">
         <router-link :to="{name:item.name}">{{ item.meta.title }}</router-link>
       </li>
     </ul>
   </template>

    <template v-if="teamRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item,index) in teamRoutes" :key="index">
          <router-link :to="{name:item.name}">{{ item.meta.title }}</router-link>
        </li>
      </ul>
    </template>

  <template v-if="articleRoutes.length">
    <div class="hr"></div>
    <ul class="main-nav">
      <li v-for="(item,index) in articleRoutes" :key="index">
        <router-link :to="{name:item.name}">{{ item.meta.title }}</router-link>
      </li>
    </ul>
  </template>

  <template v-if="systemRoutes.length">
    <div class="hr"></div>
    <ul class="main-nav">
      <li v-for="(item,index) in systemRoutes" :key="index">
        <router-link :to="{name:item.name}">{{ item.meta.title }}</router-link>
      </li>
    </ul>
  </template>
  </div>
</template>

<script>
import {toolsRoutes1,articleRoutes,teamRoutes,systemRoutes} from '../../router'
import {util, _data, _methods} from '../../common/public'

export default {
  name: 'PageSidebar',
  data() {
    return {
      ..._data,
      routes: [],
      toolsRoutes1:[],
      articleRoutes: [],
      teamRoutes:[],
      systemRoutes: [],
      curRouteName: '',
      userInfo: {},
      curRole: 0,
      systemConfig: {}
    }
  },
  created() {
    this.curRouteName = this.$route.name
    this.systemConfig = util.getLocalStorage('systemConfig', {})
    this.userInfo = util.getLocalStorage("userInfo", { id: "", role: 100 });
    this.curRole = this.userInfo.role - 0;
    this.processData();
  },
  methods: {
    ..._methods,
    _jump(e) {
      let {path} = e.target.dataset;
      this.$router.push({
        path: path
      })
    },
    processData() {
      this.auth = this.userInfo.auth ? JSON.parse(this.userInfo.auth) : [];
      this.auth_classify = this.userInfo.auth_classify ? JSON.parse(this.userInfo.auth_classify) : [];
      console.log("this.auth_classify", this.auth_classify);
      this.auth_array = [];
      for (let item of this.auth) {
        this.auth_array.push(item.routeClient);
      }

      this.auth_classify_array = [];
      for (let item of this.auth_classify) {
        this.auth_classify_array.push(item.alias);
      }
      console.log("this.auth_classify_array", this.auth_classify_array);

      this.toolsRoutes1 = this.moveItem(toolsRoutes1);
      this.articleRoutes = this.moveItem(articleRoutes);
      this.teamRoutes = this.moveItem(teamRoutes);
      this.systemRoutes = this.moveItem(systemRoutes);

      let authRoutesArray = [
        ...this.toolsRoutes1,
        ...this.articleRoutes,
        ...this.teamRoutes,
        ...this.systemRoutes,
      ];
      console.log("authRoutes", authRoutesArray);
    },
    moveItem(arr) {
      if (this.userInfo.role - 0 < 3) {
        return arr;
      }

      let _array = util.extend(true, [], arr);
      for (let i = _array.length - 1; i >= 0; i--) {
        if (_array[i].meta.classify === "home") {
          continue;
        }
        let authClassify = _array[i].meta.authClassify || "";
        if (this.auth_classify_array.indexOf(authClassify) === -1) {
          _array.splice(i, 1);
        } else {
          if (_array[i].children.length) {
            for (let j = _array[i].children.length - 1; j >= 0; j--) {
              let authClassifyItem = _array[i].children[j].meta.authClassify || "";
              if (this.auth_classify_array.indexOf(authClassifyItem) === -1) {
                _array[i].children.splice(j, 1);
              }
            }
          }
        }
      }
      console.log("_array", _array);
      return _array;
    },
  },
}
</script>

<style lang="scss" scoped>
.site-name {
  height: 70px;
  font-size: 26px;
  padding-left: 24px;
  margin-bottom: 8px;
  border-bottom: var(--bg-primary-1) solid 1px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.sidebar {
  width: var(--sidebar-width);
  background: var(--bg-primary);
  color: var(--t-color);
  font-size: 16px;

  height: 100vh;
  overflow: auto;
  border-right:var(--bg-primary-1) solid 1px;

  scrollbar-width: thin;
  scrollbar-color:var(--bg-primary-3) var(--bg-primary-4);

  .sub-title {
    padding: 0 14px 10px;
    line-height: 1.5;
    border-left: #503a7d solid 2px;
    font-size: 18px;
  }
  .sub-title .small {
    font-size: 13px;
  }

  .hr {
    margin-top: 5px;
    margin-bottom: 5px;
    border-bottom: var(--bg-primary-1) solid 1px;
  }

  a {
    font-size: 16px;
    color: var(--t-color-muted);
    display: block;
    line-height: 42px;
    padding: 0 20px;
    border-left: transparent solid 4px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s;
    &:hover{
      background: var(--bg-primary-hover);
    }
  }
}

.sidebar a.router-link-active {
  border-left-color: var(--color-primary);
  color: var(--color-primary);
}

.selectYear {
  padding: 8px;
  margin-bottom: 8px;
}
</style>