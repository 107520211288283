/*
 * axios 全局配置
 * @author: https://www.51xuediannao.com/
 * @update: 2021-09-23 12:02
 * */
import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import { _CONFIG } from "@/config/config.js";
import { util } from "@/common/util.js";

// 构建axios实例
const $http = axios.create({
  baseURL: process.env.VUE_APP_API,
  //timeout: 10000  // 设置请求超时连接时间
});
//请求发送后端之前
$http.interceptors.request.use(
  (config) => {
    config.headers["content-type"] = "application/x-www-form-urlencoded";
    config.headers["Cache-Control"] = "no-cache";
    config.headers.Authorization = util.getLocalStorage("TOKEN") || "head";
    config.headers.Config = util.getLocalStorage("TOKENCONFIG") || "head";
    //if (_CONFIG.ENV === "development") {}
    config.headers["Env-Temp"] = _CONFIG.ENV;

    //替换URL
    config.url = config.url.replace("{boss}", _CONFIG.api.boss);
    config.url = config.url.replace("{www}", _CONFIG.api.www);

    return config; //对config处理完后返回，下一步将向后端发送请求
  },
  (error) => {
    //当发生错误时，执行该部分代码
    console.log("request-error", error);
    return Promise.reject(error);
  }
);

//数据返回后，具体页面加载之前
$http.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (typeof res === "string") {
      ElMessage({
        message: "Error：" + res,
        type: "error",
        duration: 4 * 1000,
      });
      return response;
    }

    if (response.status === 401) {
      ElMessage({
        message: "Error：未授权",
        type: "error",
        duration: 4 * 1000,
      });
      return response;
    }

    // 可以对状态码进行一些拦截
    if (res.Code > 199 && res.Code < 300) {
      let msg = res.Msg || res.Content || "OK";
      if (res.Code > 200 && msg !== "OK") {
        ElMessage({
          message: res.Msg || res.Content || "成功",
          type: "success",
          duration: 2 * 1000,
        });
      }
      return response;
    } else {
      ElMessage({
        message: res.Msg || res.Content || "Error",
        type: "error",
        duration: 4 * 1000,
      });
      return response;
    }
  },
  (error) => {
    console.log("response-error", error.message);
    if (typeof error.response !== "undefined" && error.response.status === 401) {
      util.clearLocalStorage();
      ElMessage({
        message: "用户未登录或者登录已过期，请重新登录",
        type: "error",
        duration: 4 * 1000,
        onClose() {
          location.reload();
        },
      });
      return Promise.reject(error);
    }

    let message = error.message;
    if (message === "Network Error") {
      message = "后端接口网络连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    } else {
      message = error.message;
    }

    ElMessageBox({
      title: "Error",
      message: message,
      type: "error",
    }).then();

    return Promise.reject(error);
  }
);

export { $http };
