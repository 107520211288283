/**
 * Created by Administrator on 2020/11/26.
 */
import { $http } from "../common/axios.js";
import { ServicesGlobal } from "./services.js";

class LoginServices extends ServicesGlobal {
  constructor(props) {
    super(props);
  }

  show(data = {}) {
    return $http
      .get(`{boss}/user/show`, { params: data })
      .then(function(response) {
        return response.data;
      });
  }

  showBusiness(data = {}) {
    return $http
      .get(`{boss}/user/showBusiness`, { params: data })
      .then(function(response) {
        return response.data;
      });
  }

  detail(id, data = {}) {
    return $http
      .get(`{boss}/user/details/${id}`, { params: data })
      .then(function(response) {
        return response.data;
      });
  }

  delete(ids, data = {}) {
    let params = this.postParams(data);
    return $http
      .put(`{admin}/user/delete/${ids}`, params)
      .then(function(response) {
        return response.data;
      });
  }

  create(data = {}) {
    let params = this.postParams(data);
    return $http
      .post(`${this.path.api}/admin/create`, params)
      .then(function(response) {
        return response.data;
      });
  }

  update(id, data = {}) {
    let params = this.postParams(data);
    return $http
      .put(`${this.path.api}/user/update/${id}`, params)
      .then(function(response) {
        return response.data;
      });
  }

  resetPassword(id, data = {}) {
    let params = this.postParams(data);
    return $http
      .put(`${this.path.api}/user/resetPassword/${id}`, params)
      .then(function(response) {
        return response.data;
      });
  }

  statusChange(data = {}) {
    let params = this.postParams(data);
    return $http
      .put(`${this.path.api}/admin/statusChange`, params)
      .then(function(response) {
        return response.data;
      });
  }

  getCurrentAdminChildren(data = {}) {
    return $http
      .get(`${this.path.api}/admin/getCurrentAdminChildren`, { params: data })
      .then(function(response) {
        return response.data;
      });
  }

  setYearQuarter(data = {}) {
    let params = this.postParams(data);
    return $http
      .put(`${this.path.api}/admin/setYearQuarter`, params)
      .then(function(response) {
        return response;
      });
  }
}

export default new LoginServices();
