import { createRouter, createWebHashHistory } from "vue-router";
import open from "@/layout/open";
import platform from "@/layout/platform";
import datav from "@/layout/Datav";

const openRoutes = [
  {
    path: "/login",
    component: open,
    redirect: "/login",
    name: "login",
    meta: { title: "登录", classify: "login", icon: "login", affix: true, isHistoryNav: 0, isShow: 0 },
    children: [
      {
        path: "/login",
        component: () => import("@/views/login/index"),
        name: "login",
        meta: { title: "登录", classify: "login", icon: "login", affix: true, isHistoryNav: 0, isShow: 0 },
      },
    ],
  },
];

const toolsRoutes1 = [
  {
    path: "/",
    component: platform,
    redirect: "/home",
    name: "home",
    meta: {
      title: "首页",
      classify: "home",
      icon: "home",
      affix: true,
      isHistoryNav: 0,
      isShow: 1,
      role: 10,
      authClassify: "home",
    },
    children: [
      {
        path: "/home",
        component: () => import("@/views/home/index"),
        name: "homeIndex",
        meta: {
          title: "首页",
          classify: "home",
          icon: "home",
          affix: true,
          isHistoryNav: 0,
          isShow: 1,
          role: 10,
          authClassify: "home",
        },
      },
    ],
  },
  {
    path: "/datav",
    component: datav,
    redirect: "/datav/all",
    name: "datav",
    meta: {
      title: "活动数据",
      classify: "datav",
      icon: "datav",
      affix: true,
      isHistoryNav: 0,
      isShow: 1,
      role: 10,
      authClassify: "datav",
    },
    children: filterByKey([
      {
        path: "all",
        component: () => import("@/views/datav/all"),
        name: "datavIndex",
        meta: {
          title: "数据统计",
          classify: "datav",
          icon: "datav",
          affix: true,
          isHistoryNav: 0,
          isShow: 1,
          role: 10,
          authClassify: "datav",
        },
      },
    ]),
  },
];

const articleRoutes = [
  {
    path: "/activity",
    component: platform,
    redirect: "/activity/index",
    name: "activity",
    meta: {
      title: "活动管理",
      classify: "activity",
      icon: "activity",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 10,
      authClassify: "activity",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/activity/index"),
        name: "activityIndex",
        meta: {
          title: "活动管理",
          classify: "activity",
          icon: "activity",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 10,
          authClassify: "activity",
        },
      },
      {
        path: "detail/:id",
        component: () => import("@/views/activity/detail"),
        name: "activityDetail",
        meta: {
          title: "活动详情",
          classify: "activity",
          icon: "activity",
          affix: true,
          isHistoryNav: 0,
          isShow: 1,
          role: 10,
          authClassify: "activity",
        },
      },
    ]),
  },
  {
    path: "/playersRecord",
    component: platform,
    redirect: "/playersRecord/index",
    name: "playersRecord",
    meta: {
      title: "活动订单",
      classify: "playersRecord",
      icon: "playersRecord",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 10,
      authClassify: "playersRecord",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/playersRecord/index"),
        name: "playersRecordIndex",
        meta: {
          title: "活动订单",
          classify: "playersRecord",
          icon: "playersRecord",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 10,
          authClassify: "playersRecord",
        },
      },
      {
        path: "detail/:id",
        component: () => import("@/views/playersRecord/detail"),
        name: "playersRecordDetail",
        meta: {
          title: "活动订单",
          classify: "playersRecord",
          icon: "playersRecord",
          affix: true,
          isHistoryNav: 0,
          isShow: 1,
          role: 10,
          authClassify: "playersRecord",
        },
      },
    ]),
  },
  {
    path: "/invitedRecord",
    component: platform,
    redirect: "/invitedRecord/index",
    name: "invitedRecord",
    meta: {
      title: "邀请记录",
      classify: "invitedRecord",
      icon: "invitedRecord",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 10,
      authClassify: "invitedRecord",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/invitedRecord/index"),
        name: "invitedRecordIndex",
        meta: {
          title: "邀请记录",
          classify: "invitedRecord",
          icon: "invitedRecord",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 10,
          authClassify: "invitedRecord",
        },
      },
    ]),
  },
  {
    path: "/openRecord",
    component: platform,
    redirect: "/openRecord/index",
    name: "openRecord",
    meta: {
      title: "打开记录",
      classify: "openRecord",
      icon: "openRecord",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 10,
      authClassify: "openRecord",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/openRecord/index"),
        name: "openRecordIndex",
        meta: {
          title: "打开记录",
          classify: "openRecord",
          icon: "openRecord",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 10,
          authClassify: "openRecord",
        },
      },
    ]),
  },
  {
    path: "/withdrawal",
    component: platform,
    redirect: "/withdrawal/index",
    name: "withdrawal",
    meta: {
      title: "提现申请",
      classify: "withdrawal",
      icon: "withdrawal",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 10,
      authClassify: "withdrawal",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/withdrawal/index"),
        name: "withdrawalIndex",
        meta: {
          title: "提现申请",
          classify: "withdrawal",
          icon: "withdrawal",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 10,
          authClassify: "withdrawal",
        },
      },
    ]),
  },
  {
    path: "/referrer",
    component: platform,
    redirect: "/referrer/index",
    name: "referrer",
    meta: {
      title: "推广员",
      classify: "referrer",
      icon: "referrer",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 10,
      authClassify: "referrer",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/referrer/index"),
        name: "referrerIndex",
        meta: {
          title: "推广员",
          classify: "referrer",
          icon: "referrer",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 10,
          authClassify: "referrer",
        },
      },
      {
        path: "detailsList/:id",
        component: () => import("@/views/referrer/detailsList"),
        name: "referrerDetailsList",
        meta: {
          title: "推广员业绩",
          classify: "referrer",
          icon: "referrer",
          affix: true,
          isHistoryNav: 0,
          isShow: 1,
          role: 10,
          authClassify: "referrer",
        },
      },
    ]),
  },
  {
    path: "/business",
    component: platform,
    redirect: "/business/index",
    name: "business",
    meta: {
      title: "业务人员",
      classify: "business",
      icon: "business",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 10,
      authClassify: "business",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/business/index"),
        name: "businessIndex",
        meta: {
          title: "业务人员",
          classify: "business",
          icon: "business",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 10,
          authClassify: "business",
        },
      },
      {
        path: "detailsList/:id",
        component: () => import("@/views/business/detailsList"),
        name: "detailsListIndex",
        meta: {
          title: "业务人员推广情况",
          classify: "business",
          icon: "business",
          affix: true,
          isHistoryNav: 0,
          isShow: 1,
          role: 10,
          authClassify: "business",
        },
      },
      {
        path: "shareList/:id",
        component: () => import("@/views/business/shareList"),
        name: "shareListIndex",
        meta: {
          title: "业务人员分享打开情况",
          classify: "business",
          icon: "business",
          affix: true,
          isHistoryNav: 0,
          isShow: 1,
          role: 10,
          authClassify: "business",
        },
      },
    ]),
  },
  /*{
        path: '/notice',
        component:platform,
        redirect: '/notice/index',
        name: "notice",
        meta: { title: '通知管理', classify: 'notice', icon: 'notice', affix: true, isShow: 0 },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/notice/index'),
                name: 'noticeIndex',
                meta: { title: '通知管理', classify: 'notice', icon: 'notice', affix: true, isShow: 1 }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/notice/detail'),
                name: 'noticeDetail',
                meta: { title: '通知详情', classify: 'notice', icon: 'notice', affix: true, isShow: 1 }
            }
        ])
    },
    {
        path: '/slideshow',
        component:platform,
        redirect: '/slideshow/index',
        name: "slideshow",
        meta: { title: '轮播图管理', classify: 'slideshow', icon: 'slideshow', affix: true, isShow: 0 },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/slideshow/index'),
                name: 'slideshowIndex',
                meta: { title: '轮播图管理', classify: 'slideshow', icon: 'slideshow', affix: true, isShow: 1 }
            },
        ])
    },*/
];

const teamRoutes = filterByKey([
  {
    path: "/team",
    component: platform,
    redirect: "/team/index",
    name: "team",
    meta: {
      title: "团队管理",
      classify: "team",
      icon: "team",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 9,
      authClassify: "team",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/team/index"),
        name: "teamIndex",
        meta: {
          title: "团队管理",
          classify: "team",
          icon: "team",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 9,
          authClassify: "team",
        },
      },
      {
        path: "detail/:id",
        component: () => import("@/views/team/detail"),
        name: "teamDetail",
        meta: {
          title: "团队详情",
          classify: "team",
          icon: "team",
          affix: true,
          isHistoryNav: 0,
          isShow: 1,
          role: 9,
          authClassify: "team",
        },
      },
    ]),
  },
  {
    path: "/admin",
    component: platform,
    redirect: "/admin/index",
    name: "admin",
    meta: {
      title: "团队管理员",
      classify: "admin",
      icon: "admin",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 10,
      authClassify: "admin",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/admin/index"),
        name: "adminIndex",
        meta: {
          title: "管理员管理",
          classify: "admin",
          icon: "admin",
          isHistoryNav: 1,
          affix: true,
          isShow: 1,
          role: 10,
          authClassify: "admin",
        },
      },
      {
        path: "detail/:id",
        component: () => import("@/views/admin/detail"),
        name: "adminDetail",
        meta: {
          title: "管理员详情",
          classify: "admin",
          icon: "admin",
          isHistoryNav: 0,
          affix: true,
          isShow: 1,
          role: 10,
          authClassify: "admin",
        },
      },
    ]),
  },
]);

const systemRoutes = filterByKey([
  {
    path: "/boss",
    component: platform,
    redirect: "/boss/index",
    name: "boss",
    meta: {
      title: "平台管理员",
      classify: "boss",
      icon: "boss",
      affix: true,
      isHistoryNav: 1,
      level: 2,
      role: 2,
      isShow: 1,
      authClassify: "boss",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/boss/index"),
        name: "bossIndex",
        meta: {
          title: "平台管理员",
          classify: "boss",
          icon: "boss",
          affix: true,
          isHistoryNav: 1,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "boss",
        },
      },
      {
        path: "detail/:id",
        component: () => import("@/views/boss/detail"),
        name: "bossDetail",
        meta: {
          title: "添加/编辑管理员",
          classify: "boss",
          icon: "boss",
          affix: true,
          isHistoryNav: 0,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "boss",
        },
      },
    ]),
  },
  {
    path: "/auth",
    component: platform,
    redirect: "/auth/index/0",
    name: "auth",
    meta: {
      title: "授权管理",
      classify: "auth",
      icon: "auth",
      affix: true,
      isHistoryNav: 1,
      level: 2,
      role: 2,
      isShow: 1,
      authClassify: "auth",
    },
    children: filterByKey([
      {
        path: "index/:id",
        component: () => import("@/views/auth/index"),
        name: "authIndex",
        meta: {
          title: "授权管理",
          classify: "auth",
          icon: "auth",
          affix: true,
          isHistoryNav: 1,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "auth",
        },
      },
      {
        path: "options",
        component: () => import("@/views/auth/options"),
        name: "authOptions",
        meta: {
          title: "分类选项管理",
          classify: "auth",
          icon: "auth",
          affix: true,
          isHistoryNav: 0,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "auth",
        },
      },
    ]),
  },
  {
    path: "/system",
    component: platform,
    redirect: "/system/index",
    name: "system",
    meta: {
      title: "系统配置",
      classify: "system",
      icon: "system",
      affix: true,
      isHistoryNav: 1,
      isShow: 1,
      role: 10,
      authClassify: "system",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/system/index"),
        name: "systemIndex",
        meta: {
          title: "系统配置",
          classify: "system",
          icon: "system",
          affix: true,
          isHistoryNav: 1,
          isShow: 1,
          role: 10,
          authClassify: "system",
        },
      },
      {
        path: "edit/logo",
        component: () => import("@/views/system/edit/logo"),
        name: "systemLogo",
        meta: { title: "LOGO编辑", classify: "system", icon: "system", affix: true, level: 3, role: 2, isShow: 1 },
      },
    ]),
  },
  {
    path: "/dbBlast",
    component: platform,
    redirect: "/dbBlast/index",
    name: "dbBlast",
    meta: {
      title: "数据库比对",
      classify: "dbBlast",
      icon: "dbBlast",
      affix: true,
      isHistoryNav: 0,
      level: 2,
      role: 2,
      isShow: 1,
      authClassify: "dbBlast",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/dbBlast/index"),
        name: "dbBlastIndex",
        meta: {
          title: "数据库比对",
          classify: "dbBlast",
          icon: "dbBlast",
          affix: true,
          isHistoryNav: 0,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "dbBlast",
        },
      },
    ]),
  },
  {
    path: "/sysLog",
    component: platform,
    redirect: "/sysLog/index",
    name: "sysLog",
    meta: {
      title: "系统日志",
      classify: "sysLog",
      icon: "sysLog",
      affix: true,
      isHistoryNav: 1,
      level: 2,
      role: 2,
      isShow: 1,
      authClassify: "sysLog",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/sysLog/index"),
        name: "sysLogIndex",
        meta: {
          title: "系统日志",
          classify: "sysLog",
          icon: "sysLog",
          affix: true,
          isHistoryNav: 1,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "sysLog",
        },
      },
    ]),
  },
  {
    path: "/sqlLog",
    component: platform,
    redirect: "/sqlLog/index",
    name: "sqlLog",
    meta: {
      title: "SQL日志",
      classify: "sqlLog",
      icon: "sqlLog",
      affix: true,
      isHistoryNav: 1,
      level: 2,
      role: 2,
      isShow: 1,
      authClassify: "sqlLog",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/sqlLog/index"),
        name: "sqlLogIndex",
        meta: {
          title: "SQL日志",
          classify: "sqlLog",
          icon: "sqlLog",
          affix: true,
          isHistoryNav: 1,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "sqlLog",
        },
      },
    ]),
  },
  {
    path: "/gzh",
    component: platform,
    redirect: "/gzh/index",
    name: "gzh",
    meta: {
      title: "公众号操作",
      classify: "gzh",
      icon: "gzh",
      affix: true,
      isHistoryNav: 1,
      level: 2,
      role: 2,
      isShow: 1,
      authClassify: "gzh",
    },
    children: filterByKey([
      {
        path: "index",
        component: () => import("@/views/gzh/index"),
        name: "gzhIndex",
        meta: {
          title: "公众号操作",
          classify: "gzh",
          icon: "gzh",
          affix: true,
          isHistoryNav: 1,
          level: 2,
          role: 2,
          isShow: 1,
          authClassify: "gzh",
        },
      },
    ]),
  },
]);

const constantRoutes = [...openRoutes, ...toolsRoutes1, ...articleRoutes, ...teamRoutes, ...systemRoutes];

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
});

//TODO: 先实现下一级的过滤，
function filterByKey(arrayObj) {
  let index = arrayObj.findIndex((item) => {
    return item["meta"]["isShow"] === 0;
  });
  if (index > -1) {
    arrayObj.splice(index, 1);
  }
  return arrayObj;
}

export { openRoutes, toolsRoutes1, articleRoutes, teamRoutes, systemRoutes, constantRoutes, router };
