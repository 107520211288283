/**
 * Created by Administrator on 2020/11/26.
 */
import {$http} from '../common/axios.js';

import {ServicesGlobal} from './services.js';

class ManageService extends ServicesGlobal {
    constructor(props) {
        super(props);
    }

    create(data={}){
        let params = this.postParams(data);
        return $http.post(`${this.path.api}/team/create`, params)
            .then(function (response) {
                return response.data;
            });
    }

    delete(id){
        return $http.delete(`${this.path.api}/team/delete/${id}`)
            .then(function (response) {
                return response.data;
            });
    }

    update(id,data={}){
        let params = this.postParams(data);
        return $http.put(`${this.path.api}/team/update/${id}`, params)
            .then(function (response) {
                return response.data;
            });
    }

    show(data={}) {
        return $http.get(`${this.path.api}/team/show`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }

    showAll(data = {}) {
        return $http.get(`${this.path.api}/team/showAll`, {params: data})
            .then(function (response) {
                return response.data;
            });
    }

    details(id) {
        return $http.get(`${this.path.api}/team/details/${id}`)
            .then(function (response) {
                return response.data;
            });
    }

    move(data={}){
        let params = this.postParams(data);
        return $http.put(`${this.path.api}/team/move`, params)
            .then(function (response) {
                return response.data;
            });
    }

    sort(data={}){
        let params = this.postParams(data);
        return $http.put(`${this.path.api}/team/sort`, params)
            .then(function (response) {
                return response.data;
            });
    }

    getCurrentTeamChildren(data={}){
        return $http.get(`${this.path.api}/team/getCurrentTeamChildren`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }

    makeCache(){
        return $http.get(`${this.path.api}/team/makeCache`)
            .then(function (response) {
                return response.data;
            });
    }

    getQrCodeList(data={}){
        return $http.get(`${this.path.api}/team/weCodeList`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }

    getQrCode(data){
        return $http.get(`${this.path.api}/team/weCode`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }
}


export default (new ManageService())